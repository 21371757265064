import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Call from "../../assets/img/call (1).svg";
import Btn from "../../assets/img/BTN.svg";
import tick from "../../assets/img/tick.svg";
import clock from "../../assets/img/clock.svg";
import down from "../../assets/img/down.svg";
import lefty from "../../assets/img/lefty.svg";
import place from "../../assets/img/page.svg";
import One_Ad from "../../assets/img/One Ad.svg";
import custom_ad from "../../assets/img/custom-ad.png";
import alert from "../../assets/img/alert.svg";
import elements from "../../assets/img/elements.svg";
import call from "../../assets/img/call (3).svg";
import verified from "../../assets/img/verified.svg";
import "./Body.scss";

import servicesRo from "../Data/ServiceRo";
import servicesRu from "../Data/ServiceRu.js";
import servicesEn from "../Data/ServiceEn.js";
import { LanguageContext } from "../../contexts/language.context.jsx";
import Reviews from "../reviews/reviews.component.jsx";

const Body = ({ searchQuery }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    navigate(-1);
  };
  const { t } = useTranslation();
  const [isShowMore, setisShowMore] = useState(false);
  let setShow = () => {
    setisShowMore(!isShowMore);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const [services, setServices] = useState([]);
  const { selectedLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments[1];

    if (lang === "ro") {
      setServices(servicesRo);
    } else if (lang === "ru") {
      setServices(servicesRu);
    } else {
      setServices(servicesEn);
    }
  }, [location.pathname, selectedLanguage]);

  const { serviceName } = useParams();
  const service = services.find(
    (service) => service.url === decodeURIComponent(serviceName)
  );
  if (!service) {
    return <div>Service not found</div>;
  }
  return (
    <>
      <Link onClick={handleBackClick} className="other-service">
        <img src={lefty} alt="" /> {t("serviceDetailsPage.moveBack")}
      </Link>
      <div className="main-services sm-p ">
        <div className="blocks">
          <img className="rad8" src={service.bigImage} alt="" />
          <div className="single-service">
            <div className="inner-data">
              <div className="service-detail">
                <div className="col-det">
                  <p className="headf">
                    {service.name}
                    <img src={verified} alt="" />
                  </p>
                  <div className="service-ico">
                    {service.icons.map((icon) => {
                      const iconStyle = {
                        opacity: icon.available ? 1 : 0.5,
                        filter: icon.available ? "none" : "grayscale(100%)", 
                      };
                      return (
                        <img
                          src={icon.url}
                          style={iconStyle}
                          alt="icon"
                          key={icon.url}
                        />
                      );
                    })}
                  </div>

                  {service.alert ? (
                    <p className="alert  avb no-border">
                      <img src={alert} alt="" />
                      {service.alert}{" "}
                    </p>
                  ) : null}
                  <p
                    className={`lighter lit2 ${
                      isExpanded ? "expanded" : "collapsed"
                    }`}
                  >
                    {service.description}
                  </p>
                  <button className="show-more" onClick={toggleText}>
                    {isExpanded
                      ? t("serviceDetailsPage.sm")
                      : t("serviceDetailsPage.sl")}{" "}
                    <img src={down} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-cards">
            <div className="card-inner">
              <h2 className="headf1">{t("serviceDetailsPage.contact")}</h2>
              <p className="pees">{t("serviceDetailsPage.contactNote")}</p>
              <p className="mail-ph">
                <img src={call} alt="" />{" "}
                <a href={`tel:${service.phone}`}>{service.phone}</a>
              </p>
              <a
                href={service.mapAddress}
                target="_blank"
                className="mail-ph bllo"
              >
                <img src={elements} alt="" />{" "}
                <span>
                  <span className="for-desk">{service.address}</span>
                  <span className="for-mbl">{service.address}</span>{" "}
                </span>
              </a>
              <p className="mail-ph">
                <img src={clock} alt="" /> {service.orar}
              </p>
            </div>
            <div className="card-inner">
              <h2 className="headf1">{t("serviceDetailsPage.gal")}</h2>
              <div className="galleryContainer">
                <div className="firstImg">
                  <img src={service.gallery[0]} alt="" />
                </div>
                <div className="secondImg">
                  <img src={service.gallery[1]} alt="" />
                </div>
                <div className="thirdImg">
                  <img src={service.gallery[2]} alt="" />
                </div>
                <div className="fourthImg">
                  <img src={service.gallery[3]} alt="" />
                </div>
                <div className="fifthImg">
                  <img src={service.gallery[4]} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="single-service">
            <div className="inner-data">
              <div className="service-detail">
                <div className="col-det">
                  <p className="headf1">{t("serviceDetailsPage.service")}</p>
                  <div className="flex-detail">
                    <div className="col-one">
                      <img src={service.serviceTypes[0].icon} alt="" />
                      <div>
                        <h2 className="col-head">
                          {service.serviceTypes[0].title}
                        </h2>
                        <ul className="unlist">
                          {Object.values(service.serviceTypes[0].services).map(
                            (service, index) => (
                              <li key={index}>
                                <img src={tick} alt="" /> {service}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    {service.serviceTypes.length === 1 ? (
                      <div></div>
                    ) : (
                      <div className="col-one">
                        <img src={service.serviceTypes[1].icon} alt="" />
                        <div>
                          <h2 className="col-head">
                            {t(service.serviceTypes[1].title)}
                          </h2>
                          <ul className="unlist">
                            {Object.values(
                              service.serviceTypes[1].services
                            ).map((service, index) => (
                              <li key={index}>
                                <img src={tick} alt="" /> {t(service)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  {isShowMore && (
                    <>
                      <div className="flex-detail mapedCategories">
                        {service.serviceTypes.slice(2).map((element) => (
                          <div className="col-one">
                            <img src={element.icon} alt="" />
                            <div>
                              <h2 className="col-head">{element.title}</h2>
                              <ul className="unlist">
                                {Object.values(element.services).map(
                                  (service, index) => (
                                    <li key={index}>
                                      <img src={tick} alt="" /> {service}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {isShowMore ? (
                    <button className="show-more" onClick={setShow}>
                      {t("serviceDetailsPage.sm")}{" "}
                      <img style={{ rotate: "180deg" }} src={down} alt="" />
                    </button>
                  ) : (
                    <button className="show-more" onClick={setShow}>
                      {t("serviceDetailsPage.sl")} <img src={down} alt="" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div to="/ServiceDetail" className="single-service">
            <div className="inner-data">
              <div className="service-detail">
                <div className="col-det w-100">
                  <p className="headf1">{t("serviceDetailsPage.map")}</p>

                  <p className="get-d">{t("serviceDetailsPage.mapcons")}</p>
                  <iframe
                    src={service.iframeSrc}
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>

          <Reviews placeId={service.placeID} language={selectedLanguage} />

          <div className="ads-ara">
            {" "}
            <a href="https://carkeys.md/" target="_blank">
              <img src={custom_ad} alt="" />
            </a>
          </div>
          <img className="pga hide" src={place} alt="" />
          <p className="fixauto">{t("homePage.services.fixauto")}</p>
        </div>
        <div className="ads-area">
          {" "}
          <a href="https://carkeys.md/" target="_blank">
            <img src={custom_ad} alt="" />
          </a>
          <img src={One_Ad} className="hide" alt="" />
        </div>
      </div>
      <div className="mbl-foot mbl-only">
        <a className="mapButton" href={service.mapAddress} target="_blank">
          <img src={Btn} alt="" />
          <span className="description">{t("serviceDetailsPage.mapInd")}</span>
        </a>
        <a className="href-call" href={`tel:${service.phone}`}>
          <div className="call">
            <img src={Call} alt="" />
            <span>{t("serviceDetailsPage.call")}</span>
          </div>

          <div className="reminder">
            <span>{t("serviceDetailsPage.reminder")}</span>
          </div>
        </a>
      </div>
    </>
  );
};

export default Body;
