import { useState, useEffect, useRef } from "react";
import { ReactComponent as LeftArrow } from "../../assets/img/llefty.svg";
import { ReactComponent as RightArrow } from "../../assets/img/righty.svg";
import { ReactComponent as Star } from "../../assets/img/star.svg";
import { ReactComponent as StarVoid } from "../../assets/img/starvoid.svg";
import { CarouselContainer } from "./carousel.styles.jsx";
import { useTranslation } from "react-i18next";

const Carousel = ({
  placeDetails,
  itemsToShow = 1,
  autoplay = false,
  autoplayInterval = 5000,
}) => {
	const { t } = useTranslation();
  const testimonials = placeDetails.reviews;
  const totalItems = testimonials.length;
  const [current, setCurrent] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const carouselRef = useRef(null);

  // Helper function to calculate the exact item width including margins
  const calculateItemWidth = () => {
    const carousel = carouselRef.current;
    if (!carousel) return 0;

    const item = carousel.querySelector(".testimonial-card");
    const itemStyles = window.getComputedStyle(item);
    const itemWidth = item.offsetWidth;
    const marginRight = parseFloat(itemStyles.marginRight);

    return itemWidth + 5;
  };

  const handleNext = () => {
    if (isTransitioning) return;

    const itemWidth = calculateItemWidth();
    const maxPosition = totalItems - itemsToShow;

    if (current < maxPosition) {
      setIsTransitioning(true);
      setCurrent((prev) => Math.min(prev + 1, maxPosition));
    }
  };

  const handlePrev = () => {
    if (isTransitioning) return;

    if (current > 0) {
      setIsTransitioning(true);
      setCurrent((prev) => Math.max(prev - 1, 0));
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsTransitioning(false), 300);
    return () => clearTimeout(timeout);
  }, [current]);

  useEffect(() => {
    if (autoplay && totalItems > itemsToShow) {
      const interval = setInterval(handleNext, autoplayInterval);
      return () => clearInterval(interval);
    }
  }, [autoplay, autoplayInterval, totalItems]);

  // Calculate the total translateX value based on the current position and item width
  const calculateTranslateX = () => {
    const itemWidth = calculateItemWidth();
    return -(current * itemWidth);
  };

  return (
    <CarouselContainer itemsToShow={itemsToShow}>
      <div className="carouselHeader">
        <div className="placeRating">
          <h1>{t("serviceDetailsPage.reviews")}</h1>{" "}
          <h2>{placeDetails.rating}</h2> <Star />
        </div>
        <div className="buttonsContainer">
          <button
            className={`arrow left-arrow ${current === 0 ? "disabled" : ""}`}
            onClick={handlePrev}
            aria-label="Previous testimonial"
            disabled={current === 0} // Disable when at the first testimonial
          >
            <LeftArrow />
          </button>
          <button
            className={`arrow right-arrow ${
              current >= totalItems - itemsToShow ? "disabled" : ""
            }`}
            onClick={handleNext}
            aria-label="Next testimonial"
            disabled={current >= totalItems - itemsToShow} // Disable when at the last testimonial
          >
            <RightArrow />
          </button>
        </div>
      </div>
      <div className="carousel-wrapper" ref={carouselRef}>
        <div className="carousel-track-wrapper">
          <div
            className="carousel-track"
            style={{
              transform: `translateX(${calculateTranslateX()}px)`,
              transition: isTransitioning
                ? "transform 0.3s ease-in-out"
                : "none",
            }}
          >
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card" key={index}>
                <div className="testimonial-top">
                  <div className="testimonial-header">
                    <div className="testimonial-rating">
                      {Array.from(
                        { length: testimonial.rating },
                        (_, index) => (
                          <Star key={index} />
                        )
                      )}
                      {Array.from(
                        { length: 5 - testimonial.rating },
                        (_, index) => (
                          <StarVoid key={index} />
                        )
                      )}
                    </div>
                    <p className="testimonial-time">
                      {testimonial.relative_time_description}
                    </p>
                  </div>
                  <p className="testimonial-review">{testimonial.text}</p>
                </div>
                <div className="reviewer-info">
                  <img
                    src={testimonial.profile_photo_url}
                    alt={testimonial.author_name}
                    className="testimonial-image"
                  />
                  <div className="reviewer-stats">
                    <div className="testimonial-name">
                      {testimonial.author_name}
                    </div>
                    <div className="reviewer-status">Google reviewer</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CarouselContainer>
  );
};

export default Carousel;
